export class FontCache<K, V> {
  /**
   * 缓存失效时长(ms)
   *
   * @static
   * @memberof FontCache
   */
  static ExpiredAt = 10e3;

  #entries = new Map<string, V>();
  // #fonts;
  #persistentKeys = new Set<string>();

  #getKey: (keyInfo: K) => string;

  constructor(getKey: (keyInfo: K) => string) {
    if (typeof getKey !== 'function') {
      throw Error('a function to get key must be provided');
    }
    this.#getKey = getKey;
    new CycleTimer(FontCache.ExpiredAt, () => {
      this._autoClearAction();
    });
  }

  _autoClearAction() {
    // console.log('auto clear');
    for (const key of this.#entries.keys()) {
      if (!this.#persistentKeys.has(key)) {
        // console.log('delete font in cache', key);
        this.#entries.delete(key);
      }
    }
  }

  has(keyInfo: K) {
    return this.#entries.has(this.#getKey(keyInfo));
  }

  get(keyInfo: K) {
    return this.#entries.get(this.#getKey(keyInfo));
  }

  set(keyInfo: K, value: V) {
    if (!keyInfo) {
      throw TypeError('buffer不能为空');
    }
    this.#entries.set(this.#getKey(keyInfo), value);
  }

  delete(keyInfo: K) {
    const key = this.#getKey(keyInfo);
    if (!this.#persistentKeys.has(key)) {
      // console.log('delete font in cache', key);
      this.#entries.delete(key);
      return true;
    }
    return false;
  }

  setPersistent(keyInfo: K) {
    this.#persistentKeys.add(this.#getKey(keyInfo));
  }

  cancelPersistent(keyInfo: K) {
    this.#persistentKeys.delete(this.#getKey(keyInfo));
  }
}
class CycleTimer {
  period: number;
  timeout: any;
  handler: () => void;

  constructor(period: number, handler: () => void) {
    if (!isFinite(period)) {
      throw TypeError('period must be a finite number');
    }
    this.period = period;
    this.handler = handler;
    this._setup();
  }

  _setup() {
    this.timeout = setTimeout(() => {
      this.handler();
      this._setup();
    }, this.period);
  }
}

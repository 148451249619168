// import * as fontkit from 'fontkit';
import { eachOfLimit } from 'async';
import { FontSource, getBuildInFontPaths, testUrlIfUseFetch } from './config';
import type { FontItem, FontPathGroup, FontRecord, FontType, MFont, SubFontItem, openFontPathsOptions } from './types';

/**
 * 根据lang从字体的names中获取一个有效的字符串名称
 * @return {string}
 */
export function getFontName(names: Record<string, string>, lang = 'en'): string {
  if (!names) {
    return '';
  }
  // names对象内可能存在无法被fontKit正确解析的值，这些值以Buffer或类数组的类型存在
  const isValid = (s: any) => typeof s === 'string' && s;
  const getName = (lang: string) => (isValid(names[lang]) ? names[lang] : null);
  return getName(lang) ?? getName('en') ?? Object.values(names).find((s) => isValid(s)) ?? '';
}

/**
 * 从字体文件对象中解析出一个或多个字体信息
 */
export const parseFont = (font: MFont, type: FontType, path: string): FontRecord | undefined => {
  // 部分字体文件，如 Avenir.ttc，其 font.name.records 对象会有 preferredFamily, preferredSubfamily 属性，
  // Mac字体册分组时会优先使用preferredFamily, preferredSubfamily，
  // 所以不建议使用 font.familyName 和 font.subfamilyName，
  // 而是从 font.name.records 里解析出 familyName 和 subfamilyName
  const { name, fullName, postscriptName } = font;

  const nameRecords = name?.records ?? {};
  const familyNames = nameRecords.preferredFamily ?? nameRecords.fontFamily ?? {};
  const family = getFontName(familyNames);
  const subfamilyNames = nameRecords.preferredSubfamily ?? nameRecords.fontSubfamily ?? {};
  const subfamily = getFontName(subfamilyNames);

  if (!family || !subfamily || family.startsWith('.')) {
    return;
  }

  return {
    type,
    path,
    postscriptName,
    fullName,
    familyName: family,
    subfamilyName: subfamily,
    familyNames: familyNames,
    subfamilyNames: subfamilyNames,
  };
};

/**
 * 提取解析TTF文件中的FontVariation，并添加到目标fonts内
 *
 * Note:
 * 关于具有fontVariations的TTF文件的一些细节
 * 1. fontVariations的相关信息存放在 font.fvar.instance 内
 * 2. fontVariations实际的多语言名称不是存放在 font.name.records 内，而是
 *    在 font.fvar.instance 内；
 * 3. 每种fontVariation实际的postscriptName需要自行组装
 */
function addTTFVariationToFonts(font: MFont, fontInfo: FontRecord, fonts: FontRecord[]) {
  fixupFontVariationName(font);

  // 提取所有fontVariations的多语言名称
  const fontVariationNames: any =
    (font?.fvar?.instance?.reduce((obj, fv: any) => (fv.name ? Object.assign(obj, { [fv.name.en]: fv.name }) : obj), {}) as Record<
      string,
      Record<string, string>
    >) ?? [];

  // 组装fontVariation的postscriptName
  function getTTFVariationPostscriptName(familyName: string, subfamilyName: string, varName: string): string {
    return `${familyName}-${subfamilyName}${varName !== 'Regular' ? `_${varName.replace(/\s+/g, '-')}` : ''}`;
  }

  // 遍历font.namedVariations，给每种变体创建相应的fontRecord
  Object.keys(font.namedVariations).forEach((varName: string) => {
    const fvarInfo = {
      ...fontInfo,
      subfamilyName: varName,
      subfamilyNames: fontVariationNames[varName],
      postscriptName: getTTFVariationPostscriptName(font.familyName, font.subfamilyName, varName),
      isVariation: true,
    };
    fonts.push(fvarInfo);
  });
}

/**
 * 使用`font.name.records.fontSubfamily`来补全`font.fvar.instance`内对象缺失的name
 *
 * Note:
 * `font.fvar.instance`数组内可能会存在没有name的对象，这种情况下访问`namedVariation`，
 * `getVariation()`会报错。此函数是为了避免此类报错问题。
 */
export function fixupFontVariationName(font: MFont) {
  if (!font?.fvar?.instance) {
    return;
  }
  const records = font?.name?.records;
  let noNameCount = 0;
  font.fvar.instance.forEach((inst) => {
    if (!inst.name) {
      const name = { ...records.fontSubfamily };
      // 多于一个instance缺失的name时，添加一个数字后辍
      if (noNameCount > 0) {
        name.en = `${name.en ?? 'unknown'}_${noNameCount}`;
      }
      inst.name = name;
      noNameCount++;
    }
  });
}

/**
 * 获取文件数据内包含的字体信息
 */
async function fontToFontRecords(fontPath: string, font?: MFont) {
  try {
    if (!font) {
      return [];
    }
    const fonts: FontRecord[] = [];
    const fontType = font.type;
    if (font.fonts) {
      // TTC文件
      font.fonts.forEach((f: MFont) => {
        const fontInfo = parseFont(f as MFont, fontType, fontPath);
        fontInfo && fonts.push(fontInfo);
      });
    } else if (font.fvar) {
      // 具有fontVariations的TTF文件
      const fontInfo = parseFont(font, fontType, fontPath);
      fontInfo && addTTFVariationToFonts(font, fontInfo, fonts);
    } else {
      // 不具有fontVariations的TTF文件，或其他类型字体文件
      const fontInfo = parseFont(font, fontType, fontPath);
      fontInfo && fonts.push(fontInfo);
    }
    return fonts;
  } catch (err) {
    logReadFontFileError(fontPath, err);
    return [];
  }
}

function logReadFontFileError(path: string, err: any) {
  console.error('ReadFontFileError:', path, '\n', err);
}

/**
 * 用于fontSubfamily排序顺序的数组，配套使用的排序函数{@link subfamilyRankSortFn}会依照数组索引进行排序
 *
 * Note:
 * 关于fontSubfamily的命名：
 * 1. fontSubfamily名称规则通常是：[是否紧缩?][粗细/字重][是否斜体?]，可以是其中的一个或多个部分，
 * 2. “是否紧缩”使用“Condensed”，“是否斜体”通常使用“Italic”，少部分使用“Oblique”。
 * 3. “粗细/字重”即font-weight取值范围是100~900，根据OpenType字体标准，定义了一些常用字重名称。
 *    详见 @see[Common weight name mapping](https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping})
 *
 * 关于fontSubfamily名称的排序
 * 1. 大多数软件在展示字体样式(fontSubfamily)列表时，通常按照其名称的“粗细/字重”小到大进行排序。
 *    斜体版本紧随未斜体之后，紧缩版本统一放最后再按照粗细排序。
 * 2. Regular的放置位置通常有两种，一种是完全依照font-weight放置，即放在Light和Medium之间，
 *    另一种则是放在列表首位。这里采取的是后者。
 * 3. 字体文件实际定义的fontSubfamily名称在大小写和空格的使用上不一定严格统一，比如'SemiBold'和'Semibold'，
 *    又比如'Extra Bold'和'ExtraBold'，都是实际可能出现的名称。
 *
 * 下面的数组只包含了常见fontSubfamily的名称，对一些相对少见的情况不列入考虑。
 * */
const subfamilyRanks: { [key: string]: number } = (function () {
  const rankList = [
    'Regular',
    'Regular Italic',
    'Italic',
    'Oblique',
    'Thin',
    'Thin Italic',
    'ExtraLight',
    'ExtraLight Italic',
    'UltraLight',
    'UltraLight Italic',
    'Light',
    'Light Italic',
    'Light Oblique',
    'Medium',
    'Medium Italic',
    'Medium Oblique',
    'Semibold',
    'SemiBold Italic',
    'Demi',
    'DemiBold',
    'DemiBold Italic',
    'Bold',
    'Bold Italic',
    'Bold Oblique',
    'ExtraBold',
    'ExtraBold Italic',
    'UltraBold',
    'UltraBold Italic',
    'Black',
    'Black Italic',
    'Black Oblique',
    'Heavy',
    'Heavy Italic',
    'Heavy Oblique',
    'ExtraBlack',
    'UltraBlack',
    'Condensed',
    'Condensed Thin',
    'Condensed Light',
    'Condensed Medium',
    'Condensed Bold',
    'Condensed ExtraBold',
    'Condensed Black',
  ];
  return rankList.reduce((obj, v, i) => Object.assign(obj, { [removeSpaceAndToLowCase(v)]: i }), {});
})();

/**
 * 根据{@link subfamilyRanks}数组顺序对传入的数据作升序排序，数组范围外的值则根据字符串顺序排序
 *
 * @param {string} a
 * @param {string} b
 * @return {*}
 */
function subfamilyRankSortFn(a: string, b: string, ranks: Record<string, number>) {
  const usedA = removeSpaceAndToLowCase(a);
  const usedB = removeSpaceAndToLowCase(b);
  const aRank = ranks[usedA] ?? 9999;
  const bRank = ranks[usedB] ?? 9999;
  // 根据数组索引顺序作判断
  if (aRank !== bRank) {
    return aRank - bRank;
  }
  // 根据字符串顺序作判断
  return usedA.localeCompare(usedB, undefined, { numeric: true });
}

// 字体符串去除空格并转换为小写字母
function removeSpaceAndToLowCase(str: string) {
  return str.replace(/\s+/g, '').toLowerCase();
}

// 判断是否仅有英文的名称（字符串只包含字母和空格）
function isEnglishOnlyName(name: string) {
  if (name && typeof name === 'string') {
    const regExp = /^[A-Za-z ]+$/;
    return name.split('').every((s) => regExp.test(s));
  } else {
    return false;
  }
}

/**
 * 常见字体样式(fontSubFamily)的中文简体和中文繁体名称
 */
const CommonFontSubFamilyZhNames = (function () {
  const nameList: [string[], string, string][] = [
    [['Thin'], '纤细体', '纖細體'],
    [['Extra Light', 'Ultra Light'], '极细体', '極細體'],
    [['Light'], '细体', '細體'],
    [['Regular', 'Normal'], '常规', '常規'],
    [['Medium'], '中等', '中等'],
    [['Semi Bold', 'Demi Bold'], '半粗体', '半粗體'],
    [['Bold'], '粗体', '粗體'],
    [['Extra Bold', 'Ultra Bold'], '特粗体', '半粗體'],
    [['Black', 'Heavy'], '黑体', '黑體'],
    [['Extra Black', 'Ultra Black'], '特黑体', '特黑體'],
  ];
  const arr: string[][] = [];
  nameList.forEach(([keys, ...rest]) => {
    keys.forEach((key) => {
      arr.push([removeSpaceAndToLowCase(key), ...rest]);
    });
  });
  return arr;
})();

/**
 * Windows平台字体文件内提供的字体样式中文名称实际是英语名称，如 { zh: 'Regular' }
 * 此函数检查key为`zh-*`的属性值是否为只包含英语的字符串，如果是则翻译成中文
 */
function translateForFixupZhName(subFont: SubFontItem) {
  const localeKeys = ['zh', 'zh-TW', 'zh-HK', 'zh-Hant'];
  localeKeys.forEach((key) => {
    const name = subFont.names[key];
    if (name && isEnglishOnlyName(name)) {
      const usedName = removeSpaceAndToLowCase(name);
      const nameItem = CommonFontSubFamilyZhNames.find(([name]) => {
        return name === usedName;
      });
      if (nameItem) {
        let zhName;
        if (key === 'zh') {
          zhName = nameItem[1]; // 简体
        } else {
          zhName = nameItem[2]; // 繁体
        }
        subFont.names[key] = zhName as string;
      }
    }
  });
}

/**
 * 打开字体路径列表并解析为字体列表
 */
export async function openFontPathsToFontItems(paths: string[], source: FontSource, options: openFontPathsOptions = {}) {
  const loadFailedList = [] as { path: string; error: any }[];
  let fontItems = {} as Record<string, FontItem>;
  const LOAD_FONT_LIMIT = 4;
  await eachOfLimit(paths, LOAD_FONT_LIMIT, async (fontPath) => {
    let currBuffer: Uint8Array;
    return loadFontBuffer(fontPath)
      .then(async (bufferView) => {
        currBuffer = bufferView;
        options.onLoadBuffer?.({
          path: fontPath,
          bufferView,
        });

        const font = await createFont(bufferView);

        return fontToFontRecords(fontPath, font);
      })
      .then((fonts) => {
        fonts.forEach((font) => {
          addFontRecordToFontGroup(font, fontItems, source);
          options.onEachFont?.({
            font,
            bufferView: currBuffer,
          });
        });
      })
      .catch((e) => {
        loadFailedList.push({
          path: fontPath,
          error: e,
        });
      });
  });
  fontItems = processFontItems(fontItems);
  if (loadFailedList.length) {
    console.warn('load failed font list', loadFailedList);
  }
  return fontItems;
}

/**
 * 添加字体解析记录对到字体分组
 */
function addFontRecordToFontGroup(font: FontRecord, fontItems: Record<string, FontItem>, source: FontSource) {
  const { familyNames, subfamilyNames, ...restFontProps } = font;
  let familyItem = fontItems[font.familyName];
  if (!familyItem) {
    // 创建 family 对象
    familyItem = {
      type: font.type,
      names: familyNames,
      subFontFamilyType: 'intergration',
      subFontFamilys: {},
      postscriptName: '',
      source,
    };
    // 添加到 family 到 group
    fontItems[font.familyName] = familyItem;
  }

  // 创建 subfamily 对象
  const subfamilyItem = {
    ...restFontProps,
    names: subfamilyNames,
    familyName: font.familyName,
    source,
  };
  // 添加 subfamily 到 family
  familyItem.subFontFamilys[font.subfamilyName] = subfamilyItem;
}

/**
 * 处理字体项（数据排序、决定subFontFamilyType、postscriptName等）
 */
function processFontItems(familyItem: { [key: string]: FontItem }) {
  // 外层fontFamily依照字符串顺序进行排序
  const fontEntries = Object.entries(familyItem).sort(([a], [b]) => {
    return a.localeCompare(b, undefined, { numeric: true });
  });

  fontEntries.forEach(([, fontFamily]) => {
    /** 内层fontSubfamily依照特定的数组顺序进行排序(具体请见{@link subfamilyRanks}的注释说明) */
    const sortedSubFonts = Object.entries(fontFamily.subFontFamilys).sort(([a], [b]) => subfamilyRankSortFn(a, b, subfamilyRanks));
    // Windows平台字体文件内提供的字体样式中文名称实际是英语名称，如 { zh: 'Regular' }, 这里增加额外翻译来修正问题
    sortedSubFonts.forEach(([, subFont]) => translateForFixupZhName(subFont));
    fontFamily.subFontFamilys = Object.fromEntries(sortedSubFonts);

    // 确定subFontFamilyType，如果某个fontFamily内的所有subFontFamily的paths具有多个不同的值，表示来自多个字体文件，则置为'single'
    if (sortedSubFonts.length > 1) {
      const paths = sortedSubFonts.map(([, subFont]) => subFont.path);
      const pathSet = new Set(paths);
      if (pathSet.size > 1) {
        fontFamily.subFontFamilyType = 'single';
      }
    }

    // 确定 familyItem 的 postscriptName
    fontFamily.postscriptName = (sortedSubFonts as any)[0][1]?.postscriptName;
  });
  const resultFonts = Object.fromEntries(fontEntries);
  return resultFonts;
}

// 获取字体路径列表（合并系统字体列表、内建字体列表）
export async function getFontPaths() {
  const fontPaths = [] as string[];
  if ((globalThis as any)?.MeApi) {
    const systemFonts = (await (globalThis as any)?.MeApi?.getFontPaths?.()) as string[];
    fontPaths.push(...systemFonts);
  }
  const buildInFontPaths = getBuildInFontPaths();
  if (buildInFontPaths.length > 0) {
    fontPaths.push(...buildInFontPaths);
  }
  return fontPaths;
}

export async function getGroupedFontPaths() {
  const fontPathGroups = [] as FontPathGroup[];
  const buildInFontPaths = getBuildInFontPaths();
  // 内置字体
  if (buildInFontPaths.length > 0) {
    fontPathGroups.push({
      key: FontSource.BUILD_IN,
      paths: buildInFontPaths,
    });
  }
  // 系统字体
  if ((globalThis as any)?.MeApi) {
    const systemFonts = ((await (globalThis as any)?.MeApi?.getFontPaths?.()) as string[] | undefined) ?? [];
    if (systemFonts.length > 0) {
      fontPathGroups.push({
        key: FontSource.SYSTEM,
        paths: systemFonts,
      });
    }
  }
  // console.log('fontPathGroups: ', fontPathGroups);
  return fontPathGroups;
}

export function getOsPlatform() {
  return ((globalThis as any)?.MeApi?.getOsPlatform?.() as string) ?? '';
}

/**
 * 获取自定义文件资源host（移动端需要使用自定义协议访问本地系统文件资源, 使用此自定义host替换文件资源的http origin）
 */
export function getCustomFileHost() {
  return (globalThis as any)?.MeApi?.getFetchFileHost?.() ?? '';
}

let fontKit: any;
export async function getFontKit() {
  if (!fontKit) {
    fontKit = await import('fontkit');
  }
  return fontKit;
}

export async function createFont(buffer: Uint8Array, postscriptName: string = '') {
  if (!buffer) {
    return;
  }
  const fontkit = await getFontKit();

  return fontkit.create(buffer, postscriptName) as MFont;
}

export async function openFont(url: string, postscriptName: string = '') {
  const buffer = await loadFontBuffer(url);

  return createFont(buffer, postscriptName);
}

export async function loadFontBuffer(url?: string) {
  if (!url) {
    return;
  }
  let buffer;
  const willUseFetch = testUrlIfUseFetch(url);
  if ((globalThis as any)?.MeApi && !willUseFetch) {
    buffer = await (globalThis as any)?.MeApi?.fileHelper?.read?.(url);
  } else {
    buffer = await fetchUrlAsUint8Array(url);
  }

  return buffer;
}

async function fetchUrlAsUint8Array(url: string) {
  const res = await fetch(url);
  return new Uint8Array(await res.arrayBuffer());
}

/**
 * 使用fontLocaleKey从字体文件内的多语言names对象内取值，依次使用 'en'、'0-0'作为备选项（部分字体名称没有en但有0-0属性）
 * 对于中文，还会额外去尝试寻找 'zh-TW'、'zh-Hant'、'zh'版本名称
 */
export function getFontLocaleName(fontNames: Record<string, string> = {}, fontLocaleKey: string = 'en') {
  if (!fontNames) {
    return '';
  }
  // names对象内可能存在无法被fontKit正确解析的值，这些值以Buffer或类数组的类型存在
  const isValid = (s: any) => typeof s === 'string' && s;
  const getName = (lang: string) => (isValid(fontNames[lang]) ? fontNames[lang] : null);
  return (
    getName(fontLocaleKey) ??
    (fontLocaleKey.startsWith('zh') ? getName('zh-HK') ?? getName('zh-TW') ?? getName('zh-Hant') ?? getName('zh') : null) ??
    getName('en') ??
    Object.values(fontNames).find((s) => isValid(s)) ??
    ''
  );
}

export function isNonEmptyString(s: any) {
  return typeof s === 'string' && s;
}

/**
 *
 * 需要遍历字符串的时候使用此方法分割字符串为数组，用于解决Array.from(str), [...str], for...of循环
 * 等方式无法把包含有Emoji、特殊字符的字符串正确地依照Unicode编码分割的问题
 *
 * @example
 * toUnicodeChars('☺️✡︎') // 返回 ['☺', '✡']，字符分割正确
 * Array.from('☺️✡︎')     // 返回 ['☺', '️', '✡', '︎']，字符分割错误
 *
 * 关于字符串中包含非BMP字符的更多信息
 * @see {@link [String.prototype.charCodeAt() - JavaScript | MDN](https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/String/charCodeAt)}
 */
export function toUnicodeCharArray(str: string) {
  let result;
  if (typeof str === 'string' && str) {
    const validRegex =
      /\ud83c[\udffb-\udfff](?=\ud83c[\udffb-\udfff])|(?:[^\ud800-\udfff][\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]?|[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\ud800-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
    result = str.match(validRegex) as string[] | null;
  }
  return result ?? [];
}

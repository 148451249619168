import { FontCache } from './cache';
import {
  FontSource,
  getBuildInFallbackFont,
  getDefaultFont,
  getFontFallbackList,
} from './config';
import {
  createFont,
  fixupFontVariationName,
  getGroupedFontPaths,
  loadFontBuffer,
  openFontPathsToFontItems,
  parseFont,
  toUnicodeCharArray,
} from './helpers';
import type {
  BBox,
  FontData,
  FontInfo,
  FontItemGroup,
  FontPathGroup,
  GlyphData,
  MFont,
  MGlyph,
  SubFontItem,
  TextFontAttrs,
} from './types';

const DEFAULT_FONT_SIZE = 25.4; // 72pt = 1in = 25.4mm

export class FontManager {
  // 字体列表（FontItems表)
  private fontList?: Record<string, FontItemGroup>;
  // 获取字体列表时的promise缓存
  private _getFontListPromise?: Promise<Record<string, FontItemGroup>>;

  /**
   * font数据缓存
   * 缓存自动失效时长：{@link FontCache.ExpiredAt}
   */
  #fontCache = new FontCache<FontData, MFont>((info) => {
    if (!info.familyName || !info.subfamilyName || !info.source) {
      console.warn('familyName, subfamilyName或source不应该为空', info);
      return '';
    }
    return `[${info.familyName},${info.subfamilyName}],${info.source}`;
  });

  /**
   * char到glyph映射缓存
   */
  private charToGlyph = new Map<string, string | undefined>();
  /**
   * glyph数据缓存
   */
  private glyphCache = new Map<string, GlyphData>();
  /**
   * fontInfo数据缓存
   */
  private fontInfoCache = new Map<string, Partial<FontInfo>>();

  currentFont?: FontData;

  constructor() {
    this._init();
  }

  private _init() {
    const fonts = [getDefaultFont(), getBuildInFallbackFont()];
    fonts.forEach((fontData) => {
      this.#fontCache.setPersistent(fontData);
      this.resolveFont(fontData);
    });
  }

  /**
   * 获取分组的字体列表
   * @cached
   */
  async getGroupedFontList() {
    if (this.fontList) {
      return this.fontList;
    }
    if (this._getFontListPromise) {
      return this._getFontListPromise;
    }
    console.time('getFontList');
    this._getFontListPromise = getGroupedFontPaths().then(
      this._getFontGroupsAction,
    );
    this.fontList = await this._getFontListPromise;
    return this.fontList;
  }

  private async _getFontGroupsAction(pathGroups: FontPathGroup[]) {
    const fontGroups = {} as Record<string, FontItemGroup>;
    const promises = [];
    for (const pathGroup of pathGroups) {
      const promise = openFontPathsToFontItems(
        pathGroup.paths,
        pathGroup.key,
        // 内置字体需要添加用于css样式的FontFace，才能使字体选项的label文本具有字体样式效果
        pathGroup.key === FontSource.BUILD_IN
          ? {
              onEachFont: ({ font, bufferView }) =>
                addFontFace(font.postscriptName, bufferView),
            }
          : undefined,
      ).then((fonts) => {
        // 创建 group 对象
        const fontGroup: FontItemGroup = {
          key: pathGroup.key,
          fonts,
        };
        fontGroups[pathGroup.key] = fontGroup;
      });
      promises.push(promise);
    }
    return Promise.allSettled(promises).then(() => fontGroups);
  }

  /**
   * 预加载指定文字的glyph数据
   * @cached
   */
  async preloadTextGlyphs(data: {
    fontFamily: string;
    fontSubfamily: string;
    fontSource: string;
    text: string;
    fontSize?: number;
  }) {
    if (!data) {
      return;
    }
    const {
      fontFamily,
      fontSubfamily,
      fontSource,
      text,
      fontSize = DEFAULT_FONT_SIZE,
    } = data;
    const fontData: FontData = {
      familyName: fontFamily,
      subfamilyName: fontSubfamily,
      source: fontSource,
    };

    for (const char of toUnicodeCharArray(text)) {
      const charKey = this._charKeyOf(char, fontData);
      if (this.charToGlyph.has(charKey)) {
        continue;
      }
      let glyphKey;
      const res = await this._resolveCharGlyph(
        char,
        fontSize,
        this.fontsForSearchGlyph(fontData),
      ).catch(console.warn);
      if (res) {
        const { fontData: glyphUsedFont, glyphData, hasDef } = res;
        glyphKey = this._charKeyOf(hasDef ? char : '(.notdef)', glyphUsedFont);
        if (!this.glyphCache.has(glyphKey)) {
          this.glyphCache.set(glyphKey, glyphData);
        }
      }
      this.charToGlyph.set(charKey, glyphKey);
    }
  }

  /**
   * 同步地从缓存中获取指定文字的glyph数据
   * @cached
   */
  getTextGlyphsSync(data: {
    fontFamily: string;
    fontSubfamily: string;
    fontSource: string;
    text: string;
    fontSize?: number;
  }) {
    if (!data) {
      return;
    }
    const {
      fontFamily,
      fontSubfamily,
      fontSource,
      text,
      fontSize = DEFAULT_FONT_SIZE,
    } = data;
    const fontData: FontData = {
      familyName: fontFamily,
      subfamilyName: fontSubfamily,
      source: fontSource,
    };
    const glyphData: any = {};
    for (const char of toUnicodeCharArray(text)) {
      const charKey = this._charKeyOf(char, fontData);
      let glyphKey = this.charToGlyph.get(charKey);
      if (glyphKey) {
        glyphData[char] = this.glyphCache.get(glyphKey);
      } else {
        // const timeLabel = `find glyph [${charKey}]`;
        // console.time(timeLabel);
        const res = this._getGlyphSync(
          char,
          fontSize,
          this.fontsForSearchGlyph(fontData),
        );
        if (res) {
          const { fontData: glyphUsedFont, glyphData: glyph, hasDef } = res;
          glyphKey = this._charKeyOf(
            hasDef ? char : '(.notdef)',
            glyphUsedFont,
          );
          if (!this.glyphCache.has(glyphKey)) {
            this.glyphCache.set(glyphKey, glyph);
          }
          this.charToGlyph.set(charKey, glyphKey);
          glyphData[char] = glyph;
        }
        this.charToGlyph.set(charKey, glyphKey);
        // console.timeEnd(timeLabel);
      }
    }

    let fontInfo: Partial<FontInfo> = {};
    const fontKey = this._fontKeyOf(fontData);
    if (this.fontInfoCache.has(fontKey)) {
      fontInfo = this.fontInfoCache.get(fontKey) as Partial<FontInfo>;
    } else {
      const targetFont =
        this._getCachedFont(fontData) ?? this._getCachedFont(getDefaultFont());
      if (targetFont) {
        fontInfo = this.getFontInfo(targetFont, fontSize);
        this.fontInfoCache.set(fontKey, fontInfo);
      }
    }
    const result = {
      fontInfo,
      glyphData,
    };
    return result;
  }

  private getFontInfo(targetFont: MFont, fontSize: number) {
    const unitsPerEm = getUnitsPerEm(targetFont);
    const fontScale = (1 / unitsPerEm || 1000) * fontSize;
    const getNum = (value: any, defaultValue = 0) => {
      const num = Number(value);
      return isFinite(num) ? num : defaultValue;
    };
    const fontInfo = {
      unitsPerEm: getNum(unitsPerEm),
      lineHeight:
        getNum(Math.abs(targetFont.ascent - targetFont.descent)) * fontScale,
      ascent: getNum(targetFont.ascent) * fontScale,
      descent: getNum(targetFont.descent) * fontScale,
      capHeight: getNum(targetFont.capHeight, 18.199) * fontScale,
      xHeight: getNum(targetFont.xHeight, 12.8651) * fontScale,
      lineGap: getNum(targetFont.lineGap) * fontScale,
    };
    return fontInfo;
  }

  setCurrentFont(data: FontData) {
    const newFont = data ?? ({} as FontData);
    const oldFont = this.currentFont ?? ({} as FontData);
    const isChanged = isSameFont(newFont, oldFont);
    if (!isChanged) {
      return;
    }

    if (this.currentFont) {
      this.clearCurrentFont();
    }

    if (data) {
      this.#fontCache.setPersistent(data);
      this.currentFont = data;
    }
  }

  clearCurrentFont() {
    if (!this.currentFont) {
      return;
    }
    const currentFont = this.currentFont;
    const persistentFonts = [getDefaultFont(), getBuildInFallbackFont()];
    const isPersistentFont = persistentFonts.find((font) =>
      isSameFont(currentFont, font),
    );
    if (!isPersistentFont) {
      this.#fontCache.cancelPersistent(currentFont);
    }
    this.currentFont = undefined;
  }

  async resolveFont(data: FontData) {
    let font: MFont | void;

    if (this.#fontCache.has(data)) {
      font = this.#fontCache.get(data);
      return font;
    } else {
      const path =
        data.path ||
        this.getFontPathInList(
          data.familyName,
          data.subfamilyName,
          data.source,
        );

      if (path) {
        font = await loadFont(path, data).catch(console.warn);

        if (font) {
          this.#fontCache.set(data, font);
        }
        if (!font) {
          console.warn('font is not available', data);
        }
        return font;
      } else {
        console.warn('font is not available', data);
      }
    }
  }

  /**
   * 获取字体缓存内的字体实例
   */
  private _getCachedFont(data: FontData) {
    return this.#fontCache.get(data);
  }

  /**
   * 获取字体路径（从字体列表中）
   */
  private getFontPathInList(
    familyName: string,
    subfamilyName: string,
    source: string,
  ) {
    console.log(['getFontPathInList', this.fontList]);

    if (!this.fontList) {
      return;
    }
    return this.fontList?.[source]?.fonts[familyName]?.subFontFamilys?.[
      subfamilyName
    ]?.path;
  }

  getFont(attrs: TextFontAttrs) {
    return this.fontList?.[attrs.fontSource]?.fonts?.[attrs.fontFamily]
      ?.subFontFamilys?.[attrs.fontSubfamily];
  }

  /**
   * 获取指定字体属性的目标字体不存在时的替代字体
   */
  getSubstitutedFontData(attrs: TextFontAttrs) {
    if (!this.fontList) {
      return;
    }
    // 存在参数指定字体属性的目标字体，停止搜索
    if (this.getFont(attrs)) {
      return;
    }
    // 寻找替代字体
    let foundFont: SubFontItem | undefined;
    // 搜索其他来源的相同字体
    let usedAttrs: TextFontAttrs;
    for (const source of Object.values(FontSource)) {
      if (source === attrs.fontSource) {
        // 跳过参数指定来源
        continue;
      }
      usedAttrs = {
        ...attrs,
        fontSource: source,
      };
      foundFont = this.getFont(usedAttrs);
      if (foundFont) {
        return usedAttrs;
      }
    }
    // 搜索参数指定来源相同family的替代subfamily字体
    // const targetFamily =
    //   this.fontList[attrs.fontSource]?.fonts?.[attrs.fontFamily];
    // if (targetFamily) {
    //   foundFont =
    //     targetFamily.subFontFamilys['Regular'] ??
    //     Object.values(targetFamily.subFontFamilys)[0];
    //   if (foundFont) {
    //     usedAttrs = {
    //       ...attrs,
    //       fontSubfamily: foundFont.subfamilyName,
    //     };
    //     return usedAttrs;
    //   }
    // }
    // 搜索不到字体，使用默认字体
    const defaultFont = getDefaultFont();
    usedAttrs = {
      fontSource: FontSource.BUILD_IN,
      fontFamily: defaultFont.familyName,
      fontSubfamily: defaultFont.subfamilyName,
    };
    return usedAttrs;
  }

  private _charKeyOf(char: string, fontData: FontData) {
    return [char, [fontData.familyName, fontData.subfamilyName].join('|')].join(
      '|',
    );
  }

  private _fontKeyOf(fontData: FontData) {
    return `[${fontData.familyName ?? ''},${fontData.subfamilyName ?? ''}],${
      fontData.source
    }`;
  }

  private async _resolveCharGlyph(
    char: string,
    fontSize: number,
    fontDataList: FontData[] | Generator<FontData, void>,
  ) {
    const codePoint = char.codePointAt(0);
    if (codePoint === undefined) {
      return;
    }
    try {
      let notDefGlyphInfo;
      for (const fontData of fontDataList) {
        const font = await this.resolveFont(fontData);
        if (font) {
          const hasDef = font.hasGlyphForCodePoint(codePoint);
          const glyph = font.glyphForCodePoint(codePoint) as MGlyph;
          const glyphData = extractGlyphData(
            glyph,
            getUnitsPerEm(font),
            fontSize,
          );
          if (!glyphData) {
            console.warn('ignore empty glyphData  for', { char });
            continue;
          }
          const isVisible = Boolean(glyphData.dPath);
          const currResult = {
            fontData,
            glyphData,
            hasDef,
            isVisible,
          };
          if (hasDef) {
            return currResult;
          }
          if (!notDefGlyphInfo && isVisible) {
            notDefGlyphInfo = currResult;
          }
        }
      }
      return notDefGlyphInfo;
    } catch (e) {
      console.error('fail resolve glyph', e);
    }
  }

  private _getGlyphSync(
    char: string,
    fontSize: number,
    fontDataList: FontData[] | Generator<FontData, void>,
  ) {
    const codePoint = char.codePointAt(0);
    if (codePoint === undefined) {
      return;
    }
    try {
      let notDefGlyphInfo;
      for (const fontData of fontDataList) {
        const font = this._getCachedFont(fontData);
        if (font) {
          const hasDef = font.hasGlyphForCodePoint(codePoint);
          const glyph = font.glyphForCodePoint(codePoint) as MGlyph;
          const glyphData = extractGlyphData(
            glyph,
            getUnitsPerEm(font),
            fontSize,
          );
          if (!glyphData) {
            console.warn('ignore empty glyphData  for', { char });
            continue;
          }
          const isVisible = Boolean(glyphData.dPath);
          const currResult = {
            fontData,
            glyphData,
            hasDef,
            isVisible,
          };
          if (hasDef) {
            return currResult;
          }
          if (!notDefGlyphInfo && isVisible) {
            notDefGlyphInfo = currResult;
          }
        }
      }
      return notDefGlyphInfo;
    } catch (e) {
      console.error('fail get glyph', e);
    }
  }

  private *fontsForSearchGlyph(fontData?: FontData) {
    if (fontData) {
      yield fontData;
    }
    yield getDefaultFont();
    yield* getFontFallbackList();
  }

  getFamilyItem(attrs: Omit<TextFontAttrs, 'fontSubfamily'>) {
    return this.fontList?.[attrs.fontSource]?.fonts?.[attrs.fontFamily];
  }
}

function getActualFont(font: MFont, fontFamily: string, subFontFamily: string) {
  if (!font) {
    return;
  }

  let actualFont;
  if (font.type === 'TTC' && Array.isArray(font.fonts)) {
    actualFont = font.fonts.find((item) => {
      const { subfamilyName } = parseFont(item, item.type, '') || {};
      return subfamilyName === subFontFamily;
    });
  } else {
    const { subfamilyName } = parseFont(font, font.type, '') || {};
    if (font.fvar) {
      fixupFontVariationName(font);
      actualFont = font.getVariation(subFontFamily);
    } else if (subfamilyName === subFontFamily) {
      actualFont = font;
    }
  }

  if (!actualFont) {
    console.warn(
      `There has no actual font of [${fontFamily}, ${subFontFamily}]`,
    );
  }
  return actualFont;
}

function extractGlyphData(
  glyph: MGlyph,
  unitsPerEm: number = 1000,
  fontSize: number = DEFAULT_FONT_SIZE,
) {
  if (!glyph) {
    return;
  }
  let advanceWidth = 0;
  let advanceHeight = 0;
  let leftBearing = 0;
  let topBearing = 0;
  const bbox: BBox = { minX: 0, minY: 0, maxX: 0, maxY: 0 };
  try {
    bbox.minX = glyph.bbox.minX;
    bbox.minY = glyph.bbox.minY;
    bbox.maxX = glyph.bbox.maxX;
    bbox.maxY = glyph.bbox.maxY;
    advanceWidth = glyph.advanceWidth;
    advanceHeight = glyph.advanceHeight;
    leftBearing = glyph._metrics?.leftBearing ?? 0;
    topBearing = glyph._metrics?.topBearing ?? 0;
  } catch (e) {
    console.error(e);
    // 解析失败时的后备处理
    if (!advanceWidth) {
      advanceWidth = bbox.maxX - bbox.minX + unitsPerEm * 0.1;
    }
    if (!advanceHeight) {
      advanceHeight = bbox.maxY - bbox.minY + unitsPerEm * 0.1;
    }
  }
  const glyphScale = (1 / unitsPerEm) * fontSize;
  const dPath = glyph.getScaledPath(fontSize).scale(1, -1).toSVG();
  Object.keys(bbox).forEach((key) => {
    (bbox as any)[key] = (bbox as any)[key] * glyphScale;
  });
  const data = {
    dPath,
    advanceWidth: advanceWidth * glyphScale,
    advanceHeight: advanceHeight * glyphScale,
    leftBearing: leftBearing * glyphScale,
    topBearing: topBearing * glyphScale,
    bbox,
  } as GlyphData;
  return data;
}

async function loadFont(url: string, data: FontData) {
  if (!url) {
    return;
  }
  const buffer = await loadFontBuffer(url);

  let font = await createFont(buffer);

  if (font) {
    font = getActualFont(font, data.familyName, data.subfamilyName);
  }
  return font;
}

async function addFontFace(postscriptName: string, bufferView: Uint8Array) {
  if (!postscriptName) {
    return;
  }
  const fontFace = new FontFace(postscriptName, bufferView);
  fontFace.load();
  if ((globalThis?.document?.fonts as any)?.add) {
    (globalThis.document.fonts as any).add(fontFace);
  }
}

function isSameFont(font1: FontData, font2: FontData) {
  return (
    font1.familyName === font2.familyName &&
    font1.subfamilyName === font2.subfamilyName &&
    font1.source === font2.source
  );
}

export function getTextFontAttrs(text: {
  style: {
    fontFamily?: string | string[];
    fontSubfamily?: string;
    fontSource?: string;
  };
}) {
  const attrs = {
    fontFamily: '',
    fontSubfamily: '',
    fontSource: '',
  } as TextFontAttrs;
  const textStyle = text.style;
  if (textStyle) {
    attrs.fontFamily =
      (Array.isArray(textStyle.fontFamily)
        ? textStyle.fontFamily[0]
        : textStyle.fontFamily) ?? '';
    attrs.fontSubfamily = textStyle.fontSubfamily ?? '';
    attrs.fontSource = textStyle.fontSource ?? '';
  }
  return attrs;
}

export function textFontAttrsToFontData(attrs: TextFontAttrs): FontData {
  return {
    familyName: attrs.fontFamily,
    subfamilyName: attrs.fontSubfamily,
    source: attrs.fontSource,
  };
}

export function fontDataToTextFontAttrs(data: FontData): TextFontAttrs {
  return {
    fontFamily: data.familyName,
    fontSubfamily: data.subfamilyName,
    fontSource: data.source,
  };
}

function getUnitsPerEm(font: MFont) {
  try {
    return font.unitsPerEm;
  } catch (e) {
    return 1000;
  }
}

export { FontSource };

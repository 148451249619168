/**
 * 使用fontLocaleKey从字体文件内的多语言names对象内取值，依次使用 'en'、'0-0'作为备选项（部分字体名称没有en但有0-0属性）
 * 对于中文，还会额外去尝试寻找 'zh-TW'、'zh-Hant'、'zh'版本名称
 */
export function getFontLocaleName(
  fontNames: Record<string, string> = {},
  fontLocaleKey: string = 'en',
) {
  if (!fontNames) {
    return '';
  }
  // names对象内可能存在无法被fontKit正确解析的值，这些值以Buffer或类数组的类型存在
  const isValid = (s: any) => typeof s === 'string' && s;
  const getName = (lang: string) =>
    isValid(fontNames[lang]) ? fontNames[lang] : null;
  return (
    getName(fontLocaleKey) ??
    (fontLocaleKey.startsWith('zh')
      ? getName('zh-HK') ??
        getName('zh-TW') ??
        getName('zh-Hant') ??
        getName('zh')
      : null) ??
    getName('en') ??
    Object.values(fontNames).find((s) => isValid(s)) ??
    ''
  );
}

// import type { FontItem } from '../store/types';

export function isNonEmptyString(s: any) {
  return typeof s === 'string' && s;
}

export function checkFontList(fontItems: any[]) {
  for (const item of fontItems) {
    const name = getFontLocaleName(item.names);
    if (!isNonEmptyString(item.value) && !isNonEmptyString(name)) {
      return false;
    }
    for (const subItem of item.subFontFamilys) {
      const subName = getFontLocaleName(subItem.names);
      if (!isNonEmptyString(subItem.value) && !isNonEmptyString(subName)) {
        return false;
      }
    }
  }
  return true;
}

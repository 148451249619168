import { TextGlyphs } from '@makeblock/m-canvas-core';
import { FontManager } from './index';

const fontManager = new FontManager();
export { fontManager as FontManager };

// @ts-ignore
TextGlyphs.measureText = fontManager.getTextGlyphsSync.bind(fontManager);

// (globalThis as any).fontManager = fontManager;

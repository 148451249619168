import { getCustomFileHost, getOsPlatform } from './helpers';
import type { FontData } from './types';

// 内置字体文件路径导入
function getFontAssetUrls() {
  const urls = Object.values(
    import.meta.glob('/assets/fonts/build-in/**/*.(ttf|ttc|otf|woff|woff2)', {
      query: '?url',
      import: 'default',
      eager: true,
    }),
  ) as string[];
  if (!urls.length) {
    console.error(
      'Error: Build-in font assets is empty, check if font directory is the wrong place!',
    );
  }
  return urls;
}

// 默认字体文件路径导入
function getDefaultFontUrl() {
  const defaultFontUrl = Object.values(
    import.meta.glob('/assets/fonts/build-in/15_Lato/Lato-Regular.ttf', {
      query: '?url',
      import: 'default',
      eager: true,
    }),
  )[0] as string;
  if (!defaultFontUrl) {
    console.error(
      'Error: Default font is empty, check if font is the wrong place!',
    );
  }
  return defaultFontUrl;
}

// 内置fallbak字体文件路径导入
function getBuildInFallbackFontUrl() {
  const buildInFallbackUrl = Object.values(
    import.meta.glob('/assets/fonts/DroidSansFallback.ttf', {
      query: '?url',
      import: 'default',
      eager: true,
    }),
  )[0] as string;
  if (!buildInFallbackUrl) {
    console.error(
      'Error: Build-in fallback font is empty, check if font is the wrong place!',
    );
  }
  return buildInFallbackUrl;
}

export enum FontSource {
  BUILD_IN = 'build-in',
  SYSTEM = 'system',
}

let buildInFontPaths: string[] | undefined;
/*
  获取内置字体列表

  Note:
  使用vite `import`语句或`import.meta.glob()`导入的文件资源的url，在不同情景下结果会有所区别

  以 `import url from '/src/assets/fonts/Arial.ttf'` 为例，
  开发环境：url为 `/src/assets/fonts/Arial.ttf`
  Web生产环境：url为 `http://localhost:3000/assets/Arial.52597982.ttf`
  Electron生产环境：url为 `http://127.0.0.1:3003/renderer/dist/assets/Arial.52597982.ttf`
*/
export function getBuildInFontPaths() {
  if (buildInFontPaths) {
    return buildInFontPaths;
  }
  const fontAssetUrls = getFontAssetUrls();
  buildInFontPaths = fontAssetUrls.map((url) => getRuntimeActualUrl(url));
  return buildInFontPaths;
}

function getRuntimeActualUrl(url: string) {
  // import文件资源的origin
  const importedFileOrigin = new URL(import.meta.url).origin;
  const customFileHost = getCustomFileHost();
  // 获取完整的绝对url
  let actualUrl = new URL(url, globalThis?.location?.origin).href;
  // 如果有自定义文件资源host，作为url前缀替换掉origin
  if (customFileHost) {
    actualUrl = actualUrl.replace(importedFileOrigin, customFileHost);
  }
  return actualUrl;
}

/**
 * 用于使用fetch()方式加载的URL的前辍匹配正则表达式
 *
 */
function getUrlMatchRegexpForFetch() {
  const customFileHost = getCustomFileHost();
  const urlPrefixList = ['http:', 'https:', 'ws:', 'wws:'];
  if (customFileHost) {
    urlPrefixList.push(customFileHost);
  }

  const regex = new RegExp(`^(${urlPrefixList.join('|')})`);
  return regex;
}

let fetchUrlRegex: RegExp | undefined;
/**
 * 判断url是否使用fetch方式加载
 */
export function testUrlIfUseFetch(url: string) {
  if (!fetchUrlRegex) {
    fetchUrlRegex = getUrlMatchRegexpForFetch();
  }
  return fetchUrlRegex.test(url);
}

// 默认字体
let defaultFont: FontData | undefined;
export function getDefaultFont(): FontData {
  if (defaultFont) {
    return defaultFont;
  }
  defaultFont = {
    familyName: 'Lato',
    subfamilyName: 'Regular',
    postscriptName: 'Lato-Regular',
    source: FontSource.BUILD_IN,
    path: getRuntimeActualUrl(getDefaultFontUrl()),
  };
  return defaultFont;
}

// 内置fallback字体
let buildInFallbackFont: FontData | undefined;
export function getBuildInFallbackFont(): FontData {
  if (buildInFallbackFont) {
    return buildInFallbackFont;
  }
  buildInFallbackFont = {
    familyName: 'Droid Sans Fallback',
    subfamilyName: 'Regular',
    postscriptName: 'DroidSansFallback',
    source: FontSource.BUILD_IN,
    path: getRuntimeActualUrl(getBuildInFallbackFontUrl()),
  };
  return buildInFallbackFont;
}

// 字体缺失字符时的默认字体，这里设置的是大多数情况下回退字体，如果设备上不存在回退的字体则显示所设置字体下的默认字符，可能是框
const FALLBACK_FONTS: Record<string, FontData[]> = {
  'win32': [
    // 微软雅黑
    {
      familyName: 'Microsoft YaHei',
      subfamilyName: 'Regular',
      postscriptName: 'MicrosoftYaHei',
      source: FontSource.SYSTEM,
    },
    // 微软正黑体（繁体中文）
    {
      familyName: 'Microsoft JhengHei',
      subfamilyName: 'Regular',
      postscriptName: 'MicrosoftJhengHeiRegular',
      source: FontSource.SYSTEM,
    },
    // 黑体
    {
      familyName: 'SimHei',
      subfamilyName: 'Regular',
      postscriptName: 'SimHei',
      source: FontSource.SYSTEM,
    },
    // 华文宋体
    {
      familyName: 'STSong',
      subfamilyName: 'Regular',
      postscriptName: 'STSong',
      source: FontSource.SYSTEM,
    },
    // 宋体
    {
      familyName: 'SimSun',
      subfamilyName: 'Regular',
      postscriptName: 'SimSun',
      source: FontSource.SYSTEM,
    },
    // Yu Gothic（日语的平假名、片假名）
    {
      familyName: 'Yu Gothic',
      subfamilyName: 'Regular',
      postscriptName: 'YuGothic-Regular',
      source: FontSource.SYSTEM,
    },
    // Malgun Gothic（韩语/朝鲜语的谚文）
    {
      familyName: 'Malgun Gothic',
      subfamilyName: 'Regular',
      postscriptName: 'MalgunGothic',
      source: FontSource.SYSTEM,
    },
  ],
  'darwin': [
    // 苹方-简
    {
      familyName: 'PingFang SC',
      subfamilyName: 'Regular',
      postscriptName: 'PingFangSC-Regular',
      source: FontSource.SYSTEM,
    },
    // 苹方-繁
    {
      familyName: 'PingFang TC',
      subfamilyName: 'Regular',
      postscriptName: 'PingFangTC-Regular',
      source: FontSource.SYSTEM,
    },
    // 华文黑体
    {
      familyName: 'STHeiti',
      subfamilyName: 'Regular',
      postscriptName: 'STHeiti',
      source: FontSource.SYSTEM,
    },
    // 华文宋体
    {
      familyName: 'STSong',
      subfamilyName: 'Regular',
      postscriptName: 'STSong',
      source: FontSource.SYSTEM,
    },
    // 简宋
    {
      familyName: 'SimSong',
      subfamilyName: 'Regular',
      postscriptName: 'SimSong-Regular',
      source: FontSource.SYSTEM,
    },
    // Toppan Bunkyu Gothic（日语的平假名、片假名）
    {
      familyName: 'Toppan Bunkyu Gothic',
      subfamilyName: 'Regular',
      postscriptName: 'ToppanBunkyuGothicPr6N-Regular',
      source: FontSource.SYSTEM,
    },
    // Nanum Gothic（韩语/朝鲜语的谚文）
    {
      familyName: 'Nanum Gothic',
      subfamilyName: 'Regular',
      postscriptName: 'NanumGothic',
      source: FontSource.SYSTEM,
    },
    // Arial Unicode MS
    {
      familyName: 'Arial Unicode MS',
      subfamilyName: 'Regular',
      postscriptName: 'ArialUnicodeMS',
      source: FontSource.SYSTEM,
    },
  ],
};

/**
 * 获取fallback字体列表
 */
export function getFontFallbackList() {
  const platform = getOsPlatform();
  const fallbackList = [getBuildInFallbackFont()].concat(
    FALLBACK_FONTS[platform] ?? [],
  );
  return fallbackList;
}

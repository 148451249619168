import type { FontItem, FontItemGroup, SubFontItem } from '@/types/canvas';
import { FontManager } from '@/utils/canvas/font/fontManager';
import { getFontLocaleName, isNonEmptyString } from '@/utils/canvas/font/fontUtils';
import { DISPLAY_TYPE } from '@makeblock/m-canvas-core';
import { has, isPlainObject } from 'lodash-es';
import { defineStore } from 'pinia';
const getDefaultAttrs = (): any => ({
  id: '',
  x: null,
  y: null,
  width: null,
  height: null,
  angle: null,
  radius: null,
  maxRadius: null,
  fill: false,
  type: '',
  lockRatio: false,
  filterList: [],
  sharpness: 50,
  grayValue: [0, 255],
  style: {},
});

export const useDisplayStore = defineStore('displayStore', {
  state: () => {
    return {
      disabled: true,
      lockScale: false,
      attrs: getDefaultAttrs(),
      fonts: [] as FontItem[],
      // fonts: [] as FontItem[],
      fontLoaded: false,
    };
  },
  getters: {
    showTextAttrsPanel(state) {
      return state.attrs.type === DISPLAY_TYPE.TEXT && !state.disabled;
    },
    showBitmapAttrsPanel(state) {
      return state.attrs.type === DISPLAY_TYPE.BITMAP && !state.disabled;
    },
  },
  actions: {
    enableDisplayPanel() {
      this.disabled = false;
    },
    resetDisplayPanelAttrs() {
      this.disabled = true;
      Object.assign(this.attrs, getDefaultAttrs());
    },
    updateDisplayAttrs(attrs: any) {
      this.setDisplayPanelAttrs(attrs);
    },
    // 更新全部属性值的时候使用
    setDisplayPanelAttrs(attrs: any) {
      for (const key in attrs) {
        const value = attrs[key];
        if (isPlainObject(value) && has(this.attrs, key)) {
          Object.assign(this.attrs[key] ?? {}, value);
        } else {
          this.attrs[key] = value;
        }
      }
    },
    async listDisplayFonts() {
      const fontGroupsMap = (await FontManager.getGroupedFontList()) as any;
      const fonts = convertFontGroupsMapToFontItems(fontGroupsMap);
      this.fonts = fonts as any;
      if (!this.fontLoaded) {
        this.fontLoaded = true;
      }
      return fonts;
    },
  },
});

/**
 * 转换为可用于UI组件显示的结构
 *
 * @param {Record<string, FontItemGroup>} fontsMap
 */
function convertFontGroupsMapToFontItems(fontsMap: Record<string, FontItemGroup>) {
  const fontGroups = [] as { key: string; children: FontItem[] }[];
  for (const { key, fonts } of Object.values(fontsMap)) {
    const children = convertFontsMapToFontItems(fonts);
    const fontGroup = {
      key: key,
      children: children,
    };
    fontGroups.push(fontGroup);
  }
  return fontGroups;
}

/**
 * 转换builder项目返回的对象map结构为可用于UI显示的array结构
 *
 * @param {Record<string, any>} fontsMap
 * @return {*}
 */
function convertFontsMapToFontItems(fontsMap: Record<string, any>) {
  const fonts = Object.entries(fontsMap).reduce((arr, [fontFamily, font]) => {
    const familyName = getFontLocaleName(font.names);
    if (!isNonEmptyString(familyName)) {
      return arr;
    }

    const subFamilys = Object.entries(font.subFontFamilys as Record<string, any>).reduce((subArr, [subFontFamily, subFont]) => {
      const subfamilyName = getFontLocaleName(subFont.names);
      if (!isNonEmptyString(subfamilyName)) {
        return subArr;
      }
      subArr.push({
        label: subfamilyName,
        value: subFontFamily,
        ...subFont,
      });
      return subArr;
    }, [] as SubFontItem[]);

    const fontItem: FontItem = {
      label: familyName,
      value: toFontFamilyOptValue({
        fontFamily,
        fontSource: font.source,
      }),
      ...font,
      subFontFamilys: subFamilys,
    };
    arr.push(fontItem);
    return arr;
  }, [] as FontItem[]);
  return fonts;
}

export function toFontFamilyOptValue(data: { fontFamily: string; fontSource: string }) {
  const target = FontManager.getFamilyItem(data);
  if (!target) {
    return data.fontFamily ?? '';
  }
  const value = JSON.stringify(data);
  return value;
}

export function fromFontFamilyOptValue(value: string) {
  let data = {
    fontFamily: '',
    fontSource: '',
  };
  try {
    if (value) {
      data = JSON.parse(value);
    }
  } catch (e) {}
  return data;
}
